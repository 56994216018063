import { afterNextRender, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, inject, Injector, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from './services/authentication/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, filter, fromEvent, map, Observable, startWith, Subject, Subscription, switchMap, take, takeUntil } from 'rxjs';
import { WindowrefService } from './services/loader/window/windowref.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'edify';
  sideMenuState: 'closed' | 'open' = 'closed';
  isMobile: boolean = false;
  isBottomNavBar: boolean = false;
  isDesktop: boolean = true;
  isLoginUrl$: Observable<boolean>;
  private resizeSubscription!: Subscription;
  loading = true; // Add loading state
  private ngUnsubscribe = new Subject<void>(); // Subject to manage subscription lifecycles
  session: any = this.authService.session;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private eRef: ElementRef,
    private router: Router,
    private windowService: WindowrefService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.isLoginUrl$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(true),
      switchMap(() => this.checkLoginUrl$())
    );
  }

  async ngOnInit(): Promise<void> {

    if (isPlatformBrowser(this.platformId)) {
      this.onResize();
      this.resizeSubscription = fromEvent(window, 'resize').pipe(
        map(() => this.onResize()),
        takeUntil(this.ngUnsubscribe)
      ).subscribe();

      this.isLoginUrl$.pipe(
        filter(isLogin => !isLogin),
        take(1),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (isPlatformBrowser(this.platformId) && this.sideMenuState === 'open' && !this.eRef.nativeElement.contains(event.target)) {
      this.toggleSidebar();
    }
  }

  toggleSidebar(): void {
    if (!this.isBottomNavBar) {
      this.sideMenuState = this.sideMenuState === 'closed' ? 'open' : 'closed';
    }
  }

  checkLoginUrl$(): Observable<boolean> {
    const subject = new BehaviorSubject<boolean>(false); // Initial value is false

    if (isPlatformBrowser(this.platformId)) {
      const window = this.windowService.nativeWindow;
      if (window) {
        const path = window.location.pathname;
        const isLogin = (path.includes('/login') || path.includes('/reset-password')
        || path.includes('/confirm-signup') || path.includes('/teacher-chat-mobile')
        || path.includes('/contact-list-mobile') || path.includes('/parent-chat-mobile')
        || path.includes('/parent-contact-mobile') || path.includes('/parent-rewards')
        || path.includes('/student-rewards') || path.includes('/teacher-rewards')
        || path.includes('/reward-details') || path.includes('/student-qrcode')
        || path.includes('/student-reward-list') || path.includes('/auth'))
        && path !== '/'; // Ensure it isn't the root path
        
        subject.next(isLogin); // Emit the actual login state
        this.loading = false; // Set loading to false after the check
      }
    }

    return subject.asObservable();
  }

  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      const window = this.windowService.nativeWindow;
      if (window) {
        this.isMobile = window.innerWidth <= 768;
        this.isBottomNavBar = this.isMobile; // Update bottom nav bar state on resize
        this.isDesktop = !this.isMobile;
      }
    }
  }

  checkLoginStatus() {
    if (this.authService.session && this.authService.currentUser && this.router.url === '/login') {
      this.navigateBasedOnRole();
    }
  }

  navigateBasedOnRole() {
    const role = this.authService.currentUser.Roles[0]?.Role.Name;

    switch (role) {
      case 'Parent':
        this.router.navigate(['parent/parent-dashboard']);
        break;
      case 'Teacher':
        this.router.navigate(['teacher/teacher-dashboard']);
        break;
      case 'Admin':
        this.router.navigate(['admin/admin-dashboard']);
        break;
      case 'Student':
        this.router.navigate(['student/student-dashboard']);
        break;
      case 'Head of School':
        this.router.navigate(['headofschool/hos-dashboard']);
        break;
      case 'Head of Department':
        this.router.navigate(['headofdepartment/hod-dashboard']);
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
  }
}
