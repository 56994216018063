import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubjectComponent } from './components/subjects/subject/subject.component';
import { SchoolsComponent } from './components/schools/schools.component';
import { LoginComponent } from './components/login/login.component';
import { LocationsComponent } from './components/locations/locations.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import { CustomersComponent } from './components/customers/customers.component';
import { StudentsComponent } from './components/students/students.component';
import { ViewTeachersComponent } from './components/teachers/teachers.component';
import { CalendarComponent } from './components/calendar/calendar/calendar.component';
import { ParentCalendarComponent } from './components/parent-calendar/parent-calendar.component';
import { TeacherCalendarComponent } from './components/teacher-calendar/teacher-calendar.component';
import { roleGuard } from './guards/role/role.guard';
import { StudentCalendarComponent } from './components/student-calendar/student-calendar.component';
import { ConfirmSignupComponent } from './components/login/confirm-signup/confirm-signup.component';
import { TeacherStudentsComponent } from './components/teacher-student/teacher-students.component';
import { TeacherDashboardComponent } from  './components/teacher-dashboard/teacher-dashboard.component';
import { StudentDashboardComponent } from './components/student-dashboard/student-dashboard.component';
import { ParentChildrenComponent } from './components/parent-children/parent-children.component';
import { ParentDashboardComponent } from './components/parent-dashboard/parent-dashboard.component';
import { HodDashboardComponent } from './components/hod-dashboard/hod-dashboard.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { HosDashboardComponent } from './components/hos-dashboard/hos-dashboard.component';
import { HosCalendarComponent } from './components/hos-calendar/hos-calendar.component';
import { HodCalendarComponent } from './components/hod-dashboard/hod-calendar/hod-calendar.component';
import { TeachersChatComponent } from './components/teachers-chat/teachers-chat.component';
import { TeachersChatMobileComponent } from './components/teachers-chat-mobile/teachers-chat-mobile.component';
import { ChatContactMobileComponent } from './components/chat-contact-mobile/chat-contact-mobile.component';
import { ParentsChatComponent } from './components/parents-chat/parents-chat.component';
import { ParentsContactMobileComponent } from './components/parents-contact-mobile/parents-contact-mobile.component';
import { ParentsChatMobileComponent } from './components/parents-chat-mobile/parents-chat-mobile.component';
import { ParentRewardsScreenComponent } from './components/parent-rewards-screen/parent-rewards-screen.component';
import { StudentRewardsScreenComponent } from './components/student-rewards-screen/student-rewards-screen.component';
import { TeacherRewardsScreenComponent } from './components/teacher-rewards-screen/teacher-rewards-screen.component';
import { RewardDetailsComponent } from './components/reward-details/reward-details.component';
import { StudentQrCodeComponent } from './components/reward-details/student-qr-code/student-qr-code.component';
import { StudentRewardListComponent } from './components/teacher-rewards-screen/student-reward-list/student-reward-list.component';


// const routes: Routes = [
//   { path: 'subject', component: SubjectComponent,canActivate: [roleGuard('Admin')]},
//   { path: 'school', component: SchoolsComponent,canActivate: [roleGuard('Admin')] },
//   { path: 'location', component: LocationsComponent,canActivate: [roleGuard('Admin')] },
//   { path: 'login', component: LoginComponent },
//   { path: 'reset-password', component: ResetPasswordComponent },
//   { path:'', redirectTo:'login',pathMatch:'full' },
//   { path: 'teachers', component: TeachersComponent,canActivate: [roleGuard('Admin')] },
//   { path: 'customer', component: CustomersComponent,canActivate: [roleGuard('Admin')]},
//   { path: 'students', component: StudentsComponent ,canActivate: [roleGuard('Admin')]},
//   { path: 'calendar', component: CalendarComponent ,canActivate: [roleGuard('Admin')]},
//   { path: '', component: LoginComponent },
// ];

// const routes: Routes = [
//   { path: 'subject', component: SubjectComponent, canActivate: [roleGuard(['Admin'])]},
//   { path: 'school', component: SchoolsComponent,canActivate: [roleGuard(['Admin'])]},
//   { path: 'location', component: LocationsComponent, canActivate: [roleGuard(['Admin'])]},
//   { path: 'login', component: LoginComponent },
//   { path: 'reset-password', component: ResetPasswordComponent },
//   { path: 'confirm-signup', component: ConfirmSignupComponent },
//   { path:'', redirectTo:'login',pathMatch:'full' },
//   { path: 'teachers', component: ViewTeachersComponent ,canActivate: [roleGuard(['Admin'])]},
//   { path: 'customer', component: CustomersComponent ,canActivate: [roleGuard(['Admin'])]},
//   { path: 'students', component: StudentsComponent,canActivate: [roleGuard(['Admin'])]},
//   { path: 'calendar', component: CalendarComponent ,canActivate: [roleGuard(['Admin'])]},
//   { path: 'teacher-calendar', component: TeacherCalendarComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'parent-calendar', component: ParentCalendarComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'student-calendar', component: StudentCalendarComponent,canActivate: [roleGuard(['Student'])]},
//   { path: 'parent-calendar/:event-id/:date/:student-id', component: ParentCalendarComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'student-calendar/:event-id/:date', component: StudentCalendarComponent,canActivate: [roleGuard(['Student'])]},
//   { path: 'teacher-students', component: TeacherStudentsComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'teacher-dashboard', component: TeacherDashboardComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'student-dashboard', component: StudentDashboardComponent,canActivate: [roleGuard(['Student'])]},
//   { path: 'parent-children', component: ParentChildrenComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'parent-dashboard', component: ParentDashboardComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'hod-dashboard', component: HodDashboardComponent,canActivate: [roleGuard(['Head of Department'])]},
//   { path: 'hod-calendar', component: HodCalendarComponent,canActivate: [roleGuard(['Head of Department'])]},
//   { path: 'admin-dashboard', component: AdminDashboardComponent,canActivate: [roleGuard(['Admin'])]},
//   { path: 'hos-dashboard', component: HosDashboardComponent,canActivate: [roleGuard(['Head of School'])]},
//   { path: 'hos-calendar', component: HosCalendarComponent,canActivate: [roleGuard(['Head of School'])]},
//   { path: 'teacher-chat', component: TeachersChatComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'teacher-chat-mobile', component: TeachersChatMobileComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'contact-list-mobile', component: ChatContactMobileComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'parent-chat', component: ParentsChatComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'parent-contact-mobile', component: ParentsContactMobileComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'parent-chat-mobile', component: ParentsChatMobileComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'parent-rewards', component: ParentRewardsScreenComponent,canActivate: [roleGuard(['Parent'])]},
//   { path: 'student-rewards', component: StudentRewardsScreenComponent,canActivate: [roleGuard(['Student'])]},
//   { path: 'teacher-rewards', component: TeacherRewardsScreenComponent,canActivate: [roleGuard(['Teacher'])]},
//   { path: 'reward-details', component: RewardDetailsComponent,canActivate: [roleGuard(['Teacher','Student','Parent'])]},
//   { path: 'student-qrcode', component: StudentQrCodeComponent,canActivate: [roleGuard(['Student','Parent'])]},
//   { path: 'student-reward-list/:studentid', component: StudentRewardListComponent,canActivate: [roleGuard(['Teacher'])]},

// ];

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),

  },
  {
    path: 'student',
    loadChildren: () => import('./features/student/student.module').then(m => m.StudentModule),
    canActivate: [roleGuard(['Student'])]
  },
  {
    path: 'teacher',
    loadChildren: () => import('./features/teacher/teacher.module').then(m => m.TeacherModule),
    canActivate: [roleGuard(['Teacher'])]
  },
  {
    path: 'parent',
    loadChildren: () => import('./features/parent/parent.module').then(m => m.ParentModule),
    canActivate: [roleGuard(['Parent'])]
  },
  {
    path:'headofschool',
    loadChildren: () => import('./features/headofschool/headofschool.module').then(m => m.HeadofschoolModule),
    canActivate: [roleGuard(['Head of School'])]
  },
  {
    path:'headofdepartment',
    loadChildren: () => import('./features/headofdepartment/headofdepartment.module').then(m => m.HeadofdepartmentModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule),
    canActivate: [roleGuard(['Admin'])]
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'login', redirectTo: 'auth/login',pathMatch: 'full' },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
