<!-- app.component.html -->
<div *ngIf="loading" class="loading-container">
  <app-circular-loader class="circular-loader"></app-circular-loader>
</div>

<div *ngIf="!loading">
  <div class="page-subjects" *ngIf="(isLoginUrl$ | async) === false">
    <div class="nav-bar-container">
      <app-top-navbar
        *ngIf="(isLoginUrl$ | async) === false"
        [isMobile]="isMobile"
        [isBottomNavBar]="isBottomNavBar"
        (sideMenuIconClick)="toggleSidebar()"
        [ngClass]="{ 'mobile-navbar': isMobile }"
      ></app-top-navbar>
    </div>
    <!-- <app-loader></app-loader> -->
    <div class="dashboard-wraper">
      <div class="dashboard-container">
        <app-side-menu
          [ngClass]="{ 'mobile-side-menu': isMobile, 'bottom-nav-bar': isMobile && isBottomNavBar }"
          id="sidemenu"
          *ngIf="(isMobile || isDesktop) && (isLoginUrl$ | async) === false"
          [role]="'admin'"
          [sidebarItemStateProp]="'default'"
          [state]="sideMenuState">
        </app-side-menu>
        <app-slide-out-side-menu
          [ngClass]="{ 'mobile-side-menu': isMobile, 'bottom-nav-bar': isMobile && isBottomNavBar }"
          id="sidemenu"
          *ngIf="(!isBottomNavBar && isMobile) && (isLoginUrl$ | async) === false"
          [role]="'admin'"
          [sidebarItemStateProp]="'default'"
          [state]="sideMenuState"
          >
        </app-slide-out-side-menu>
        <div class="middle-column">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <router-outlet *ngIf="(isLoginUrl$ | async) === true"></router-outlet>
</div>
