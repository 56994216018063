import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/authentication/auth.service';
import { RoleService } from '../../services/authentication/role-service/role.service';
import { catchError, combineLatest, map, of, take, timeout } from 'rxjs';

export function roleGuard(roles: string[]): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    const roleService = inject(RoleService);
    const router = inject(Router);

    const session = authService.session;
    const profile = authService.currentUser;
    let currentRole = roleService.currentRole;

    if (!session) {
      // No session, redirect to login
      return router.createUrlTree(['/login']);
    }

    if (profile.account_status == 'inactive' && roleService.hasRole('Teacher')) {
      // No session, redirect to login
      authService.signOut();
      return router.createUrlTree(['/login']);
    }

    if (!roles.includes(currentRole!)) {
      // Current role is not in the required roles, check if user has any of the required roles
      for (const role of roles) {
        if (roleService.hasRole(role)) {
          // Set the correct role as the current role
          roleService.currentRole = role;
          currentRole = role; // Update the currentRole variable
          break;
        }
      }

      if (!roles.includes(currentRole!)) {
        // If still no match, redirect to the default page
        return router.createUrlTree([roleService.getDefaultPagebyRole()]);
      }
    }

    return true; // Allow navigation if the correct role is found
  };
}
