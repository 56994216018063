import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowrefService {
  private window: Window | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.window = window;
    }
  }

  get nativeWindow(): Window | null {
    return this.window;
  }

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
